.table1 {
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    left: 100px;
    position: relative;
}

.statusColorRed {
    color: red;
}

.statusColorGreen {
    font-weight: bold !important;
}

.statusColorGrey {
    color: grey;
}



.statusStyle {
    display: flex;
    align-items: center;
}

.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
