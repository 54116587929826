.box {
    margin-left: 260px;
    margin-top: -10px;
}

.instance-detail {
    margin-left: 280px;
    font-weight: bold;
}

.trigger-instance {
    display: flex;
    width: 400px;
    justify-content: flex-end;
    margin-left: 7px;
}

.stop-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
}

.start-details {
    margin: 10px;
}

.date-field, .time-field {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.details {
    margin: 10px;
    font-weight: bold;
}

.account-dropdown {
    float: right;
    margin-right: 20px;
    margin-top: -20px;
}