.identitylogin .bg {
 /*background-image: url(../images/BG.png);*/
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.identitylogin {
  text-align: left;
}

.logoContainer {
  text-align: center;
}

.identitylogin .container {
  margin: 0px auto;
}

.identitylogin .logo {
  height: 2.1875rem;
  margin-top: 25vh;
  margin-bottom: 32px;
  margin-left: auto;
  margin-right: auto;
}

.identitylogin .loginForm {
  box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  color:#7b8388;
  background: 4px;
  border-radius: 4px;
}

.identitylogin .loginMessage {
  display: flex;
  -webkit-box-align: center;
  flex-direction: column;
  align-content: center;
  text-align: center;
  color: #102b45;
  font-family: "Source Sans Pro","Work Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Cantarell,"Helvetica Neue",sans-serif;
  font-size: 24px;
  font-weight: 400;
  margin: 32px auto 16px auto;
  line-height: 2.25rem;
}

/* iPhone 5 regardless of IOS version */
@media (device-height: 568px) and (device-width: 320px) and (-webkit-min-device-pixel-ratio: 2) {
  /*IPhone 5 only CSS here*/
  .identitylogin .loginMessage {
    margin: 4px auto 4px auto !important;
  }
  .identitylogin .logo {
    margin-top: 25vh !important;
  }
}
/* phone */
@media (min-width: 0px) {
  .identitylogin .loginForm {
    box-shadow: none;
    background: none;
  }
  .identitylogin .logo {
    height: 50px;
    margin-top: 25vh;
    margin-bottom: 32px;
    margin-left: auto;
    margin-right: auto;
  }
  .identitylogin .loginMessage {
    display: flex;
    -webkit-box-align: center;
    flex-direction: column;
    align-content: center;
    text-align: center;
    color: #102b45;
    font-family: "Source Sans Pro","Work Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Cantarell,"Helvetica Neue",sans-serif;
    font-size: 24px;
    font-weight: 400;
    margin: 32px auto 16px auto;
    line-height: 2.25rem;
  }
}
/* tablet_portrait */
@media (min-width: 576px) {
  .identitylogin .logo {
    margin-top: 25vh;
    margin-bottom: 50px;
  }
  .identitylogin .loginForm {
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    color: #7b8388;
    background: 4px;
    border-radius: 4px;
    padding: 10px 20px;
  }
}
/* tablet_landscape */
@media (min-width: 769px) {
  .identitylogin .loginForm {
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    color: #7b8388;
    background: 4px;
    border-radius: 4px;
    padding: 10px 30px;
  }
}
/*Ipad Pro*/
/* Portrait */
@media only screen and (min-width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  .identitylogin .logo {
    margin-top: 25vh !important;
    margin-bottom: 50px !important;
  }
}
/* laptop */
@media (min-width: 992px) {
  .identitylogin .logo {
    margin-top: 25vh;
    margin-bottom: 32px;
  }
  .identitylogin .loginForm {
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    color: #7b8388;
    background: 4px;
    border-radius: 4px;
  }
}
/* desktop+ */
@media (min-width: 1200px) {
  .identitylogin .loginForm {
    box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    color: #7b8388;
    background: 4px;
    border-radius: 4px;
    width: 630px;
    height: auto;
    margin: 0 auto;
    padding: 20px 60px;
  }
}

.signInMicrosoft {
  text-align: center;
  margin: 16px auto 32px auto;
  cursor: pointer;
}
